
import React, {useEffect} from "react"
import Layout from "../layout/Layout"
import { useQuery } from "@apollo/client";
import { DRAZBA } from "../../query/drazby";
import { Helmet } from "react-helmet";
import { format } from 'date-fns'
import { SRLWrapper } from "simple-react-lightbox";
import { Loading } from "../layout/Loading";
import {default as PDF} from "../../images/pdf.svg"

export const Drazba = ({location}) => {

    let id = 0;
    let path
    path = location.pathname.split('/');
    if(path[2]==='exekuce') {
        id = parseInt(path[3]);
    }  
     
    let queryName = DRAZBA;
    const {loading, data} = useQuery(queryName, {
        variables: {
            id: id
        }
    });

    let item = data?.drazbyFindOne || null;
    let img
    let datum

    if(item?.images?.length > 0) {
        img = item.images[0].id
    }
    item?.datumKonani && (datum = format(new Date(item.datumKonani),'dd. MM. yyyy'))
     
    const drazbaData = [
        {name: 'Datum konání', value: datum },
        {name: 'Čas konání', value: item?.navrhovatel },
        {name: 'Místo konání', value: item?.mistoKonani },
        {name: 'Poloha', value: (item?.obec + ' ' + item?.evCislo).trim() + ', ' + item?.kraj },
        {name: 'Číslo jednací', value: item?.cj }, 
        {name: 'Dražební jistota', value: item?.drazebniJistina }, 
        
    ]
 

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Dražba | Exekutorský úřad Praha 9</title> 
        </Helmet>
        <Layout location={location} pageContext={{title: item?.nazev}}>
          
                {loading ? <Loading loading={loading} /> : 
                
                
                <section className="max-w-5xl mx-auto my-5" >
                
                        
                  <div className="rounded-lg bg-white overflow-hidden  "> 
                    <div className=" p-6">

                        <div className="lg:grid lg:grid-cols-3 gap-6">
                            <div className="flex-shrink-0 max-w-sm mx-auto mt-10 mb-10 ">
                            
                            <SRLWrapper>
                            <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 mx-auto">
                                {item?.images?.length > 0 && item?.images?.map((e,index) => {
                                    return (
                                        <div key={index} className={"overflow-hidden " + (index===0 ? "col-span-2 lg:col-span-3" : "h-32 ")}>
                                    <img
                                        className="cursor-pointer object-cover min-h-full"
                                        src={'https://www.exekucepraha.cz/?e=file&id=' + e.id}
                                        alt={item?.nazev}
                                    />
                                    </div>
                                )
                            })}
                            </div>
                            </SRLWrapper>
                              
                            </div>
                             <div className="col-span-2">
                                <p className=" font-bold text-red-600 mb-3 text-center">{item?.druh}</p>

                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg"> 
                                        <div className="border-t border-gray-200">
                                        <dl>
                                            <div className={" px-4 py-3 grid grid-cols-3 sm:grid-cols-5 sm:gap-4 sm:px-6 bg-white " }>
                                                <dt className="text-sm font-medium text-gray-500 sm:col-span-2">Dokumenty</dt>
                                                <dd className=" text-sm text-gray-900 sm:mt-0 col-span-2 sm:col-span-3 space-y-2">
                                                    {item?.files.map((file,fileIndex) => {
                                                        return (
                                                            <a target="_blank" href={'https://www.exekucepraha.cz/?e=file&id=' + file.id} key={fileIndex} className={"  flex items-center space-x-4 underline hover:no-underline " }>
                                                                <img src={PDF} className={"w-8 h-8"} alt="pdf" />
                                                                <span className="text-sm font-medium text-gray-500 sm:col-span-2">{file.name}</span>
                                                            </a>
                                                        )
                                                        })}
                                                </dd>
                                            </div>
                                            {drazbaData.map((e,i) => {
                                            return (
                                                <div key={i} className={" px-4 py-3 grid grid-cols-3 sm:grid-cols-5 sm:gap-4 sm:px-6 " + (i % 2 === 0 ? "bg-gray-50" : "bg-white")}>
                                                <dt className="text-sm font-medium text-gray-500 sm:col-span-2">{e.name}</dt>
                                                <dd className=" text-sm text-gray-900 sm:mt-0 col-span-2 sm:col-span-3">{e.value}</dd>
                                                </div>
                                            )
                                            })}
                                            
                                        </dl>
                                        </div>
                                    </div>

                                    
                             </div>
                            
                        </div>
                    </div> 
                  </div>
                </section>
                }
             
        </Layout>
        </>
    )
}

import React from "react" 
import { Helmet } from "react-helmet";     
import { Drazba } from "../components/elements/Drazba";
import { DrazbyIndex } from "../components/elements/DrazbyIndex";
import { Router } from "@reach/router"
import { ApolloProvider } from "@apollo/client";
import { apolloClient  } from "../client/apollo";

const DrazbyPage = ({ location}) => {

  return (
      <>
         <Helmet>
          <meta charSet="utf-8" />
          <title>Dražby | Exekutorský úřad Praha 9</title> 
        </Helmet>  

         
      <ApolloProvider client={apolloClient}>
            <Router>
                <Drazba path="/drazby/exekuce/:id" location={location} />
                <DrazbyIndex path="/drazby" location={location} />
                <DrazbyIndex path="/drazby/strana/:page" location={location} />
            </Router>
        </ApolloProvider>
  
      </>

  )
}
 
export default DrazbyPage
 
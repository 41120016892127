import {gql} from '@apollo/client';

export const DRAZBY_LIST = gql`
    query drazby($offset: Int, $limit: Int) {
        drazbyFindAll(limit: $limit, offset: $offset) {
            id
            nazev
            kraj
            okres
            obec
            druh
            datumKonani
            cj
            images {
                id
            }
        }  
        drazbyGetCount
    }
`;
 
export const DRAZBA = gql`
    query drazbyFindOne($id: ID!) {
        drazbyFindOne(id: $id) {
            id
            nazev
            kraj
            okres
            obec
            druh
            datumKonani
            navrhovatel
            drazebniJistina
            mistoKonani
            evCislo
            cj
            images {
                id
            }
            files {
                name
                id
                type
            }
        } 
    }
`;
 